/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect } from 'react';
// * 3rd party libs
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TagManager from 'react-gtm-module';

import { Button, Divider, Grid, Icon, Typography } from '@mui/material';
import { envConfig } from 'config/config';
import flagES from 'assets/imgs/spain48.png';
import flagUK from 'assets/imgs/uk48.png';
import { Apple, Google } from '@mui/icons-material';

// * ASSETS

// * TYPES
import { LoginPost, LoginResponse, ErrorResponse, ErrorDataAxios, GoogleLoginPost } from 'store/@types/user';
import AppleLogin from 'react-apple-login';
// * COMPONENTS
import { useLanguage } from 'context/LanguageProvider';
import NoAuthPage from 'components/NoAuthPage/NoAuthPage';
import { notifications } from 'components/Page';
import Input from 'components/forms/Input';
import Loading from 'components/Loading';
import LanguagePicker from 'components/LanguagePicker';
import useAuth from 'hooks/useAuth';
import { GoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { schema, defaultValues } from './yupSchema';
import { ReduxProps } from '.';

// * TYPES

const Login: FC<ReduxProps> = ({ user, login, glogin }) => {
  const { loading } = user;
  const [match] = useSearchParams();
  const { setAuth, setTokenData } = useAuth();
  const navigate = useNavigate();
  const verifyOperation = match.get('verify') === 'true';

  const { t } = useTranslation();

  const { changeLanguage } = useLanguage();

  useEffect(() => {
    verifyOperation &&
      toast(
        <Typography>
          {t('login_account_verified') /* Your account has been verified. */}
          <br />
          {t('login_enjoy') /* Enjoy SINES! */}
        </Typography>,
        notifications.success,
      );
  }, [verifyOperation]);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<LoginPost>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  const onSubmit = (loginPayload: LoginPost) =>
    login(loginPayload, (successResponse?: LoginResponse, errorResponse?: ErrorResponse) => {
      if (successResponse) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
          },
        });
        setTokenData(successResponse);
        setAuth(successResponse.access_token);
        navigate('/home');
      } else if (errorResponse) {
        if (errorResponse.statusCode === 403) {
          toast(`${errorResponse.message}`, {
            ...notifications.success,
            toastId: 'loginError',
          });
          return;
        }
        if (errorResponse.statusCode === 401) {
          setError('password', {
            type: 'custom',
            message:
              // "These credentials don't match any account"
              t('login_credential_nomatch_error'),
          });
          return;
        }

        if (
          (errorResponse.data as ErrorDataAxios)?.error &&
          (errorResponse.data as ErrorDataAxios).error === 'invalid_grant'
        ) {
          toast(
            t('login_confirm_email_error'), // 'Please confirm your email address before you sign in'
            {
              ...notifications.success,
              toastId: 'loginError',
            },
          );
        }
      }
    });

  return (
    <>
      {loading && <Loading />}
      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        <NoAuthPage>
          <Input
            grid
            gridProps={{
              style: { marginTop: '40px' },
            }}
            required
            name="username"
            placeholder={t('login_email_username') /* Email or username */}
            control={control}
            errors={errors}
          />
          <Input
            grid
            required
            name="password"
            type="password"
            placeholder={t('login_password') /* Password */}
            control={control}
            errors={errors}
          />
          <Grid item xs={12}>
            <Typography variant="loginSection" component="p" style={(errors.password && {})}>
              {t('login_forgot_credentials') /* Forgot your login credentials? */}
            </Typography>
            <Typography variant="loginSection" component="p">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="/recover" style={{ color: '#B66481' }}>
                {t('login_reset_password') /* Reset password */}
              </a>
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '15px' }}>
            <Button variant={isValid ? 'primary' : 'disabled'} fullWidth type="submit" disabled={loading}>
              {t('login_Signin') /* Sign in */}
            </Button>
            <Divider variant="middle" orientation="horizontal" style={{ backgroundColor: '#353535' }} />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: '15px',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <AppleLogin
              // render={({ onClick, disabled }) => (
              //   <Button
              //     onClick={() => {
              //       console.log('click');
              //       onClick();
              //     }}
              //     sx={{
              //       height: '100%',
              //     }}
              //     fullWidth
              //     variant="primary"
              //     size="medium"
              //     // disabled={loading}
              //   >
              //     <Apple />
              //     <Typography>{t('login_apple') /* Sign in with Apple */}</Typography>
              //   </Button>
              // )}
              clientId={envConfig.REACT_APP_APPLE_CLIENTID}
              scope="email name"
              redirectURI={envConfig.REACT_APP_BACKEND_URL.concat('/auth/apple')}
              responseType="code id_token"
              responseMode="form_post"
              designProp={{ scale: 1, height: 40, width: 193, border: true, border_radius: 50 }}
            />
            <GoogleLogin
              shape="pill"
              theme="filled_black"
              width={200}
              containerProps={{
                style: {
                  marginTop: '10px',
                },
              }}
              size="large"
              onSuccess={async credentialResponse => {
                console.log(credentialResponse);
                await glogin(
                  credentialResponse as GoogleLoginPost,
                  (successResponse?: LoginResponse, errorResponse?: ErrorResponse) => {
                    if (successResponse) {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'login',
                        },
                      });
                      setTokenData(successResponse);
                      setAuth(successResponse.access_token);
                      navigate('/home');
                    } else if (errorResponse) {
                      if (errorResponse.statusCode === 400 || errorResponse.statusCode === 401) {
                        toast(`${errorResponse.message}`, {
                          ...notifications.error,
                          toastId: 'loginError',
                        });
                      } else {
                        toast(`Google authentication failed! Please try again`, {
                          ...notifications.error,
                          toastId: 'loginError',
                        });
                      }
                    }
                  },
                );
              }}
            />
            {/* <Google />
              <Typography>{t('login_google') /* Sign in with Google</Typography> */}
          </Grid>
          <Grid item xs={12} marginTop="20px">
            <Typography variant="loginSection" component="p">
              {t('login_need_account') /* Need an account? */}
            </Typography>

            <Typography variant="loginSection" component="p" sx={{ marginTop: '15px' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href={`${envConfig.REACT_APP_WEBSITE_URL}/pricing`} style={{ color: '#B66481' }}>
                {t('login_signup_here') /* Sign up here */}
              </a>
            </Typography>
          </Grid>
          {/* <div style={{ marginLeft: '15px' }}>
            <LanguagePicker />
          </div> */}
          {/* <Button
            onClick={() => changeLanguage('en')}
            startIcon={<img src={flagUK} alt="UK Flag" style={{ width: 24, height: 'auto' }} />}
          />
          <Button
            onClick={() => changeLanguage('es')}
            startIcon={<img src={flagES} alt="UK Flag" style={{ width: 24, height: 'auto' }} />}
          /> */}
        </NoAuthPage>
      </form>
    </>
  );
};

export default Login;
